import * as React from "react";
import Navbar from "../components/Navbar";
import HomeSection from "../components/HomeSection";
import Mission from "../components/Mission";
import Features from "../components/Features";
import Options from "../components/Options";
import OptionsEnd from "../components/OptionsEnd";
import SowosTogether from "../components/SowosTogether";
import Subscription from "../components/Subscription";
import Footer from "../components/Footer";
import Seo from "../components/Seo";

const IndexPage = () => {
    return (
        <div>
            <Seo/>
            <Navbar/>
            <HomeSection/>
            <Mission/>
            <Features/>
            <Options/>
            <OptionsEnd/>
            <SowosTogether/>
            <Subscription/>
            <Footer/>
        </div>
    );
};

export default IndexPage;
