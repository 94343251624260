import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Options() {
  return (
    <div className="container-xl pb-5">
      <div className="row pt-5">
        <div className="col-1">
          <StaticImage
            className="img-fluid d-none d-lg-block"
            src="../images/points_vertical.svg"
            alt="Marketplace"
            placeholder="none"
          />
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-center">
          <div className="">
            <StaticImage
              className="img-fluid"
              src="../images/marketplace.svg"
              alt="Marketplace"
              width={250}
              placeholder="none"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12 col-lg-10 text-center text-lg-start">
              <h2 className="fw-bolder mb-5 mt-3 mt-md-0">
                <span className="text-primary">En Sowos negociamos a tu favor productos y servicios </span>
                <span className="text-danger">con condiciones preferentes para ti </span>
                <span className="text-primary">haciendo más fácil el camino a una nueva vida financiera.</span>
              </h2>
              <div className="mx-auto mx-md-0">
                <a
                  className="btn btn-danger text-white mt-4 py-3 px-5 fw-bold"
                  href={`${process.env.GATSBY_APP_URL}/sign-up`}
                >
                  REGÍSTRATE
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1">
          <StaticImage
            className="img-fluid d-none d-lg-block"
            src="../images/points_vertical.svg"
            alt="Marketplace"
            placeholder="none"
          />
        </div>
      </div>
    </div>
  );
}
