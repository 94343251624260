import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

export default function Features() {
  return (
    <div className="bg-light-grey features">
      <div className="container-xl h-100 py-5">
        <div className="row h-100">
          <div className="col-12 text-center">
            <h2 className="text-primary fw-bolder">
              <span className="text-primary">Con Sowos decidir qué hacer con <br/> tu dinero</span>
              <span className="text-danger"> nunca fue tan fácil… ​</span>
            </h2>
            <h4 className="secondary-font-family my-5">
              Sowos te permite <span className="fw-bold">ahorrar e invertir</span> conforme a tus necesidades:​
            </h4>
          </div>
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col-lg-4 mx-md-4">
                <div className="background-pocket text-white text-center pt-5 pb-3 rounded-top">
                  <StaticImage
                    className="mx-auto mb-4"
                    src="../images/logo-sowos-pocket.svg"
                    alt="Logo sowos pocket"
                    placeholder="none"
                  />
                  <h5 className="secondary-font-family">Una cuenta de ahorro inteligente.</h5>
                </div>
                <div className="bg-white border-end border-start border-bottom border-gray rounded-bottom py-5 px-3">
                  <div style={{ minHeight: '360px'}}>
                    <ul className="secondary-font-family text-secondary ul-benefits">
                      <li className="option-selected">Rendimientos justos todos los días</li>
                      <li className="option-selected">Úsalo para metas de corto plazo</li>
                      <li className="option-selected mt-3">Disponibilidad diaria (9 a 13 hrs)</li>
                      <li className="option-selected">Sin montos mínimos</li>
                      <li className="option-selected">Sin costo de apertura</li>
                      <li className="option-selected">Cómisión mensual de 0.083%</li>
                    </ul>
                  </div>
                  <div className="text-center text-primary">
                    <a style={{ fontSize : '20px', fontWeight : 600 }} href="/SowosPocket">
                      Más información
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-5 mt-md-0">
                <div className="background-wealth text-white text-center pt-5 pb-3 rounded-top">
                  <StaticImage
                    className="mx-auto mb-4"
                    src="../images/logo-sowos-wealth.svg"
                    alt="Logo sowos wealth"
                    placeholder="none"
                  />
                  <h5 className="secondary-font-family">Invierte de manera automática.</h5>
                </div>
                <div className="bg-white border-end border-start border-bottom border-gray rounded-bottom py-5">
                  <div style={{ minHeight: '360px'}} className="mx-3">
                    <ul className="secondary-font-family text-secondary ul-benefits">
                      <li className="option-selected">Estrategias asesoradas por Columbus de México</li>
                      <li className="option-selected">Úsalo para metas de mediano y largo plazo</li>
                      <li className="option-selected">Disponibilidad semanal</li>
                      <li className="option-selected">Monto mínimo de $1,000 MXN</li>
                      <li className="option-selected">Costo único por apertura de $500 MXN</li>
                    </ul>
                    <ul className="secondary-font-family text-secondary ul-benefits bg-light-grey">
                      <li className="option-selected">Ahorro mensual domiciliado</li>
                      <li className="option-selected">Beneficios fiscales</li>
                    </ul>
                  </div>
                  <div className="text-center text-primary">
                    <a style={{ fontSize : '20px', fontWeight : 600 }} href="/SowosWealth">
                      Más información
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
