import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function OptionsEnd() {
  return (
    <div className="pb-5">
      <div className="gradient">
        <div style={{ height: "100px", overflow: "hidden" }}>
          <svg
            viewBox="0 0 500 100"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M-1.97,28.13 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
              style={{ stroke: "none", fill: "#fff" }}
            ></path>
          </svg>
        </div>
        <div className="container-xl">
          <div className="row">
            <div className="col-12 col-md-5 d-flex justify-content-end">
              <div className="m-5 w-100">
                <StaticImage
                  src="../images/double_phones.png"
                  alt="Celulares SOWOS"
                  placeholder="none"
                />
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="row mt-0 mt-md-5">
                <div className="col-12 col-lg-8 text-center text-lg-start">
                  <div className="d-none d-lg-block">
                    <StaticImage
                      className="img-fluid"
                      src="../images/points_horizontal.svg"
                      alt="Textura"
                      width={200}
                      placeholder="none"
                    />
                  </div>
                  <h2 className="fw-bolder text-primary my-5">
                    Con SOWOS tienes todo lo que necesitas{" "}
                    <span className="text-danger">
                      para generar más por tu dinero​.
                    </span>
                  </h2>
                  <h5 className="secondary-font-family mb-4">
                    SOWOS es más que una plataforma financiera, es una comunidad
                    que te ayuda a crecer,{" "}
                    <strong className="text-primary">
                      para que retomes el control de tu dinero de una forma
                      sencilla y transparente en un solo lugar.​​
                    </strong>
                  </h5>
                  <div className="mx-auto mx-md-0">
                    <a
                      className="btn btn-danger text-white mt-4 py-3 px-5 fw-bold"
                      href={`${process.env.GATSBY_APP_URL}/sign-up`}
                    >
                      REGÍSTRATE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
