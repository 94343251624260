import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import gif from "../images/sowos_animated.gif";

export default function HomeSection() {
  return (
    <div className="bg-light-grey">
      <div className="container">
        <div className="row bg-light-grey m-0">
          <div className="col-12 col-lg-6 pt-md-5">
            <div className="m-md-5 mt-5 text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start">
              <h1 className="big-text">
                Te acompañamos de la forma más simple para cumplir tus metas.
              </h1>
              <h4 className="secondary-font-family my-5 my-md-3">
                <strong>SOWOS</strong> te facilita dar los primeros pasos acercandote productos manejados por especialistas en
                ahorro e inversión que te permiten crecer.
              </h4>
            </div>
            <div className="col-12 text-center text-md-start">
              <a
                className="btn btn-danger text-white mx-5 px-5 py-3 fw-bold"
                href={`${process.env.GATSBY_APP_URL}/sign-up`}
              >
                REGÍSTRATE
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-end">
            <img className="mb-5 w-100" src={gif} alt="Sowos" />
            <div className="row m-0">
              <div className="col-12 d-flex justify-content-end ">
                <StaticImage
                  className="d-none d-lg-block d-md-block"
                  src="../images/bars.svg"
                  alt="Divisor"
                  height={20}
                  placeholder="none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
