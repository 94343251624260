import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { StaticImage } from "gatsby-plugin-image";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { SubscriptionViewModel } from '../view-models/subscription-view-model';

export default function Subscription() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const view_model = new SubscriptionViewModel();

  const subscribe = async () => {
    setSubmitted(true);
    if (!executeRecaptcha) {
      return
    }

    if(email && view_model.validateEmailFormat(email)) {
      const token = await executeRecaptcha('homepage')
      const was_subscribed = await view_model.subscribe(email, token);
      setShowError(!was_subscribed);
      setShowSuccess(was_subscribed);
    }
  }

  return (
    <div className="bg-white p-md-4 px-lg-0 py-lg-5">
      <div className="container-xl">
        <div className="row">
          <div className="col-12 col-lg-6 ps-lg-5">
            <div className="ps-lg-5 text-center text-lg-start">
              <h2 className="fw-bolder mt-3 mt-md-0 text-primary">
                ¿Aún tienes dudas?
              </h2>
              <h5 className="secondary-font-family pt-2">
                Déjanos tus datos y nos pondremos en contacto contigo
                para compartirte información, noticias, testimoniales y
                certificaciones directamente en tu correo.
              </h5>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-3 p-lg-0 pe-lg-5">
            <div className="pe-lg-5 d-flex flex-column">
              <div>
                <StaticImage
                  className="mb-4"
                  src="../images/bars.svg"
                  alt="Divisor"
                  height={20}
                  placeholder="none"
                />
              </div>
              <div className="w-full">
                <div id="mc_embed_signup" className="w-full">
                  <Form
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate w-full"
                    target="_blank"
                    noValidate
                    validated={submitted}
                  >
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="email"
                        value={email}
                        name="EMAIL"
                        className="required p-2 border border-black rounded"
                        id="mce-EMAIL"
                        placeholder="email..."
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <Form.Control
                        type="button"
                        value="ENVIAR"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="btn btn-primary text-white fw-bold bg-blue py-2 px-10 text-lg"
                        style={{maxWidth : 150}}
                        onClick={subscribe}
                      />
                    </InputGroup>
                    <Alert show={showSuccess} variant="primary">
                      Nos pondremos en contacto muy pronto.
                    </Alert>
                    <Alert show={showError} variant="danger">
                      Ocurrió un error al tratar de subscribirte.
                    </Alert>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
