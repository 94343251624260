import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

export default function Mission() {
  return (
    <div className="bg-white pt-3">
      <div className="container-xl">
        <h1 className="text-center text-primary fw-bolder mt-5">
          Nuestra misión es:
        </h1>
        <div className="p-lg-3 p-xl-5">
          <div className="row text-center mx-0 mx-xl-5 mt-3">
            <div className="col-12 col-lg-4 mt-5 mt-lg-0">
              <StaticImage
                className="mb-5"
                src="../images/health.svg"
                alt="Imagén de un corazón que denota salud."
                placeholder="none"
              />
              <div className="p-3 mx-15">
                <h4 className="text-primary secondary-font-family fw-bold">
                  Acompañarte
                </h4>
              </div>
              <h5 className="secondary-font-family">
                Nuestro objetivo es acompañarte de la forma más simple para que cumplas tus metas financieras.
              </h5>
            </div>
            <div className="col-12 col-lg-4 mt-5 mt-lg-0">
              <StaticImage
                className="mb-5"
                src="../images/people.svg"
                alt="Imagén de un un grupo de personas trabajando en conjunto."
                placeholder="none"
              />
              <div className="p-3 mx-15">
                <h4 className="text-primary secondary-font-family fw-bold">
                  Negociar por ti
                </h4>
              </div>
              <h5 className="secondary-font-family">
                En Sowos negociamos a tu favor productos y servicios con condiciones preferentes para ti.
              </h5>
            </div>
            <div className="col-12 col-lg-4 mt-5 mt-lg-0">
              <StaticImage
                className="mb-5"
                src="../images/shortcut.svg"
                alt="Imagén de dos puntos que se conectán."
                placeholder="none"
              />
              <div className="p-3 mx-15">
                <h4 className="text-primary secondary-font-family fw-bold">
                  Conectar
                </h4>
              </div>
              <h5 className="secondary-font-family">
                Entré más grande sea la comunidad, mayor poder de negociación tenemos para traer más y mejores productos.
              </h5>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <a
            className="btn btn-danger text-white mt-4 py-3 px-5 fw-bold  text-center"
            href={`${process.env.GATSBY_APP_URL}/sign-up`}
          >
            REGÍSTRATE
          </a>
        </div>
      </div>
    </div>
  );
}
