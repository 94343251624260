import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function SowosTogether() {
  return (
    <div className="bg-light-grey p-5">
      <div className="container">
        <h2 className="fw-bolder text-center text-secondary pb-3">
          ÚNETE A SOWOS<br />
          CAMINAMOS JUNTOS, GANAMOS JUNTOS
        </h2>
        <div className="row mt-5">
          <div className="pb-5 col-12 d-flex justify-content-center">
            <StaticImage
              src="../images/sowos_together.svg"
              alt="Imagen de varias personas juntos."
              placeholder="none"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
